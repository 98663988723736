import type { NextPage } from "next";
import Head from "next/head";

import Lost from "../components/Lost";

const CustomError: NextPage = () => (
  <>
    <Head>
      <title>Richard Ekwonye | 404</title>
    </Head>

    <Lost />
  </>
);

export default CustomError;
