import { useEffect, FC } from "react";
import Link from "next/link";

import SmoothArea from "../SmoothArea";
import BigAction from "../BigAction";
import AnimatedLines from "../AnimatedLines";
import SearchLightBg from "../SearchLightBg";
import { setBodyTextColor } from "../../utils";
import { HERO_TEXT_ANIMATION_DELAY } from "./Lost.data";
import styles from "./Lost.module.scss";

const Lost: FC = () => {
  useEffect(() => {
    setBodyTextColor("--white");

    return () => {
      setBodyTextColor("--black");
    };
  }, []);

  return (
    <>
      <SearchLightBg imgSrc="/images/lost.gif" />
      <section className={styles.lostContainer}>
        <SmoothArea className={styles.lost}>
          <div className={styles.lostMessageContainer}>
            <AnimatedLines
              className={styles.lostMessage}
              text="Oops, it looks like you're lost."
              animationDelay={HERO_TEXT_ANIMATION_DELAY}
              animateIntoView={false}
            />
          </div>
          <div className={styles.aboutLink}>
            <Link href="/" scroll={false}>
              <a>
                <BigAction
                  className={styles.goHome}
                  text="Back to"
                  textTwo="homepage"
                  animationDelay={HERO_TEXT_ANIMATION_DELAY}
                  animateIntoView={false}
                  srText="Back to homepage"
                />
              </a>
            </Link>
          </div>
        </SmoothArea>
      </section>
    </>
  );
};

export default Lost;
