import { useEffect, useRef, FC } from "react";

import { initSearchLight } from "./SearchLightBg.utils";
import type { SearchLightBgProps } from "./SearchLightBg.types";
import styles from "./SearchLightBg.module.scss";

const SearchLightBg: FC<SearchLightBgProps> = ({ imgSrc }) => {
  const searchLightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    initSearchLight(searchLightRef);
  }, []);

  return (
    <div className={styles.searchLightBg}>
      <img src={imgSrc} alt="" />
      <div ref={searchLightRef} className={styles.bgOverlay} />
    </div>
  );
};

export default SearchLightBg;
