import { EASE } from "./SearchLightBg.data";
import type { InitSearchLight } from "./SearchLightBg.types";

export const initSearchLight: InitSearchLight = (elementRef) => {
  const element = elementRef.current as HTMLDivElement;
  let x = window.innerWidth / 2;
  let y = window.innerHeight / 2;
  let targetX = x;
  let targetY = y;

  const onMouseMove = (e: MouseEvent) => {
    targetX = e.clientX;
    targetY = e.clientY;
  };

  const animateRadialGradient = () => {
    if (!element) {
      window.removeEventListener("mousemove", onMouseMove);
      return;
    }

    x += (targetX - x) * EASE;
    y += (targetY - y) * EASE;

    element.style.background = `radial-gradient(circle at ${x}px ${y}px, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.9) 30%)`;
    requestAnimationFrame(animateRadialGradient);
  };

  requestAnimationFrame(animateRadialGradient);

  window.addEventListener("mousemove", onMouseMove);
};
